import React from "react"

import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import bhim from "../images/bhim.png"
import debit from "../images/debit.png"
import { FiCreditCard } from "@react-icons/all-files/fi/FiCreditCard"
import styled from "styled-components"

function RadioButton(props) {
  const [value, setValue] = React.useState("debit or credit card")

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Wrapper>
      <FormControl component="fieldset">
        <FormLabel component="legend">payment method</FormLabel>
        <RadioGroup
          aria-label="payment"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <div>
            <div></div>
            <FormControlLabel
              value="debit or credit card"
              control={<Radio />}
              label={
                <>
                  <img classNmae="debit" src={debit} alt="certisured debit" />
                  <p>Debit or Credit card</p>
                </>
              }
              onClick={props.handlePayment(value)}
            />
          </div>
          <div>
            <div></div>
            <FormControlLabel
              value="upi payment"
              control={<Radio />}
              label={
                <>
                  <img clasName="bhim" src={bhim} alt="certisured upi" />
                  <p>UPI Payments</p>
                </>
              }
              onClick={props.handlePayment(value)}
            />
          </div>
        </RadioGroup>
      </FormControl>
    </Wrapper>
  )
}

export default RadioButton

const Wrapper = styled.div`
  width: 100%;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      color: #000000;
    }
  }
`