import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { cartTotal, cartSubTotal } from "../../utils/cart"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete"
import loadinggif from "../images/loading.gif"
import CheckoutCourse from "../components/CheckoutCourse/index"
import { removeFromCart } from "../components/features/cartSlice"
const taxRate = 0.1

function CartI({ data }) {
  //getting cart state from store
  const cart = useSelector((state) => state.cart.cartItems)

  //check whether the cart has items are not
  const [loading, setLoading] = useState(true)

  //show the payment section
  const [payment, setPayment] = useState()

  //gst calculate
  const gst = cartTotal(cart) * taxRate

  const dispatch = useDispatch()

  //getting data from course-demo-list and pushing it to the new array by comparing it to cart items
  const updatedCart = data.allStrapiCourseDemoLists.nodes.reduce(
    (prev, cur) => {
      cart?.map(
        (c) =>
          c.strapiId === cur.strapiId &&
          prev.push({
            price: cur.price,
            strapiId: cur.strapiId,
            course: cur.course,
            qty: 1,
          })
      )
      return prev
    },
    []
  )

  // check whether the cart exists or not
  useEffect(() => {
    cart && setLoading(false)
  }, [])

  const handleCart = (pay) => {
    setPayment(pay)
  }
  const updatedTotal = updatedCart?.reduce(
    (counter, product) => counter + product.price,
    0
  )

  const handleUpdateCart = (c) => {
    updatedCart.filter(
      (d) => d.strapiId === c.strapiId && dispatch(removeFromCart(d))
    )
  }

  return (
    <Wrapper>
      {cart?.length ? (
        <div className="heading">
          <h1>shopping cart</h1>
          <h2>{cart.length} course selected</h2>
        </div>
      ) : null}
      {cart?.length ? (
        <div className="cart_wrapper_wrapper">
          {cart?.map((c, id) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="cart_wrapper">
                <div className="cart_section">
                  <div className="course_image">
                    {c.image ? (
                      <GatsbyImage
                        image={
                          c.image[0].localFile.childImageSharp.gatsbyImageData
                        }
                        alt=""
                      />
                    ) : (
                      <img src="" alt="" />
                    )}
                  </div>
                  <div className="course_desc_wrapper">
                    <h2>{c.title}</h2>
                    <span>9 months | Online </span>
                  </div>
                </div>
                <div className="cart_amt_section">
                  <h4>
                    {updatedCart.map(
                      (d) =>
                        d.strapiId === c.strapiId &&
                        d.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                    )}
                  </h4>
                  <button
                    onClick={() => {
                      handleUpdateCart(c)
                    }}
                    className="remove_cart"
                  >
                    <AiOutlineDelete />
                  </button>
                </div>
              </div>
              {cart?.length - 1 === id ? null : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div className="border_line" />
                </div>
              )}
            </div>
          ))}

          <div className="cart_gst_section">
            {cart?.length ? (
              <div className="total_wrapper">
                <h3>SubTotal :</h3>
                <h3>
                  {cartTotal(updatedCart).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </h3>
              </div>
            ) : null}
            {cart?.length ? (
              <div className="total_wrapper">
                <h3>GST :</h3>
                <h3>
                  {gst.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </h3>
              </div>
            ) : null}
            {cart?.length ? (
              <div className="total_wrapper">
                <h2>TOTAL : </h2>
                <h2>{cartSubTotal(updatedCart, 0.1)}</h2>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {loading ? (
        <div className="empty_cart_wrapper">
          <div className="empty_cart_image">
            <img src={loadinggif} alt="loading gif" />
          </div>
        </div>
      ) : !cart?.length && !payment ? (
        <div className="empty_cart_wrapper">
          <div className="empty_cart_image">
            {/* <StaticImage
              src="../images/nocart.png"
              alt="certisured cart"
            /> */}
          </div>
          <div className="empty_cart_message">
            <h2>Your cart is empty. Keep browsing to find a course</h2>
          </div>
          <div className="empty_cart_button">
            <Link to="/course-list">keep browsing</Link>
          </div>
        </div>
      ) : null}
      {cart?.length || payment ? (
        <div className={!payment ? "checkout_form" : "checkout_form_length"}>
          <CheckoutCourse
            cart={updatedCart}
            taxRate="0.1"
            handleCart={handleCart}
            updatedTotal={updatedTotal}
          />
        </div>
      ) : null}
    </Wrapper>
  )
}

export default CartI

export const query = graphql`
  {
    allStrapiCourseDemoLists {
      nodes {
        title
        strapiId
        course
        description
        price
        popular_course
        type
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  width: 100%;
  height: auto;
  margin-top: 100px;
  padding-bottom: 100px;
  .heading {
    grid-area: auto/2/auto/6;
    @media (max-width: 996px) {
      grid-area: auto/2/auto/11;
    }
    h1 {
      font-weight: 600;
      font-size: 22px;
      color: #002f54;
      @media (max-width: 479px) {
        font-size: 18px;
      }
    }
    h2 {
      font-weight: 500;
      font-size: 22px;
      letter-spacing: 0.02em;
      color: #151515 !important;
      @media (max-width: 479px) {
        font-size: 16px;
      }
    }
  }
  .cart_wrapper_wrapper {
    grid-area: auto/2/auto/8;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    @media (max-width: 1400px) {
      grid-area: auto/2/auto/7;
      margin-right: 0px;
    }
    @media (max-width: 996px) {
      grid-area: auto/2/auto/11;
      margin-right: 0px;
    }
    .course_desc_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1.5rem;
      width: 350px;
      span {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        @media (max-width: 996px) {
          font-size: 12px;
        }
      }
      h2 {
        font-weight: 500;
        font-size: 18px;
        /* or 144% */
        letter-spacing: 0.02em;
        color: #000000;
        @media (max-width: 996px) {
          font-size: 16px;
          margin-top: 1px;
        }
      }
    }
    .cart_wrapper {
      display: flex;
      justify-content: space-between;
      margin: 1.7rem 0;
      @media (max-width: 1400px) {
        flex-direction: column;
      }
    }
    .border_line {
      width: 80%;
      height: 2px;
      background: radial-gradient(
        100.86% 10936973.14% at 100% 0%,
        #000000 0%,
        #000000 0%,
        rgba(0, 0, 0, 0) 0.01%,
        rgba(0, 0, 0, 0.19) 45.67%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .cart_section {
      display: flex;
      .course_image {
        display: block;
        .gatsby-image-wrapper {
          width: 250px;
          border-radius: 6px;
          @media (max-width: 767px) {
            width: 180px;
          }
          @media (max-width: 479px) {
            width: 129px !important;
          }
        }
      }
    }
    .cart_amt_section {
      display: flex;
      align-items: center;
      @media (max-width: 996px) {
        justify-content: flex-end;
      }
      .remove_cart {
        background: none;
        width: 55px;
        transition: 0.5s ease-in-out;
        @media (max-width: 996px) {
          width: auto;
        }
      }
      span,
      h4 {
        font-weight: 500;
        font-size: 20px;
        /* identical to box height */
        text-align: right;
        letter-spacing: 0.02em;
        color: #6f62df;
        margin-right: 5px;
        @media (max-width: 996px) {
          font-size: 18px;
        }
      }
      h4 {
        color: #000 !important;
        margin-left: 10px;
      }
      svg {
        cursor: pointer;
        font-size: 22px;
        color: #151515;
        opacity: 0.6;
      }
      .remove_cart:hover {
        transform: scale(1.1);
        svg {
          opacity: 1;
        }
      }
    }
  }
  .cart_gst_section {
    grid-area: auto/2/auto/6;
    background: #dceefe4d;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 70px;
    padding-top: 10px;
    padding-bottom: 20px;
    @media (max-width: 996px) {
      padding-right: 36px;
    }
    .total_wrapper {
      display: flex;
      width: 230px;
      justify-content: space-between;
      @media (max-width: 996px) {
        width: 200px;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      opacity: 0.6;
      @media (max-width: 996px) {
        font-size: 14px;
      }
    }
    h2 {
      font-weight: 600;
      font-size: 22px;
      /* identical to box height */
      text-transform: uppercase;
      color: #000000 !important;
      @media (max-width: 996px) {
        font-size: 18px;
      }
    }
  }
  .initiate_button {
    display: flex;
    padding: 15px 20px;
    border-radius: 50px;
    background: var(--thirdColor);
    cursor: pointer;
    min-width: 150px;
    font-size: 16px;
    justify-content: center;
    font-weight: 500;
    align-items: center;
  }
  .checkout_form {
    grid-area: auto/8/auto/11;
    background: #f8f8f8;
    height: 100%;
    margin-left: 30px;
    border-radius: 6px;
    @media (max-width: 1400px) {
      grid-area: auto/7/auto/11;
      margin-left: 20px;
      margin-top: 2rem;
    }
    @media (max-width: 996px) {
      grid-area: auto/2/auto/11 !important;
      margin-left: 0;
      margin-top: 2rem;
    }
    @media (max-width: 479px) {
      grid-area: auto/1/auto/12 !important;
      margin-left: 0;
      margin-top: 2rem;
    }
  }
  .checkout_form_length {
    grid-area: auto/2/auto/11;
    background: none;
    height: 100%;
    border-radius: 6px;
    @media (max-width: 996px) {
      margin-left: 0px;
    }
  }
  .empty_cart_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    grid-area: auto/2/auto/11;
    .empty_cart_image {
      display: flex;
      justify-content: center;
      .gatsby-image-wrapper {
        width: 45%;
        @media (max-width: 996px) {
          width: 100%;
        }
      }
    }
    .empty_cart_message {
      display: flex;
      justify-content: center;
      h2 {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #151515;
        @media (max-width: 996px) {
          font-size: 16px;
        }
      }
    }
    .empty_cart_button {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      a {
        min-width: 150px;
        padding: 15px 25px;
        border-radius: 50px;
        text-decoration: none;
        cursor: pointer;
        border: 2px solid var(--purpleColor);
        font-weight: 500;
        font-size: 18px;
        /* identical to box height */
        color: #191d38;
        @media (max-width: 996px) {
          font-size: 14px;
        }
      }
    }
  }
`
