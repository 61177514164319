// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Link } from "gatsby"
import styled from "styled-components"
import { cartTotal } from "../../../utils/cart"
import TextField from "@mui/material/TextField"
import "../Checkout/checkout.css"
import { BiRefresh } from "@react-icons/all-files/bi/BiRefresh"
import RadioButton from "../RadioButton"
import successGif from "../../images/success.gif"

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { StaticImage } from "gatsby-plugin-image"
import { useDispatch, useSelector } from "react-redux"
import { clearCart } from "../features/cartSlice"
import store from "../app/store/store"

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script")
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
}

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement
      style={{ width: "100%" }}
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
)

const SubmitButton = ({ processing, error, children, disabled }) => (
  <div>
    <button
      className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  </div>
)

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <h2 className="error_h2">{children}</h2>
  </div>
)

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <BiRefresh className="reset_icon" />
  </button>
)

const CheckoutForm = (props) => {
  const { cart, taxRate } = props
  console.log(props.handleCart)
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)

  const token = store.getState().user.token
  //   const { token, clearCart, user, lastName, userProfile, setReferesh } =
  //     React.useContext(UserContext)
  //userContext

  console.log(token);

  const dispatch = useDispatch()

  const [stripeToken, setStripeToken] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const [showPayment, setShowPayment] = React.useState("")
  const [total, setTotal] = React.useState()
  const [stripeTotal, setStripeTotal] = React.useState(cartTotal(cart))
  const [razorpaySuccess, setRazorpaySuccess] = React.useState(false)

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  })

  // const handleCreateUser = async () => {
  //   try {
  //     if (!userProfile.length) {
  //       const res = await fetch(`${process.env.STRAPI_API_URL}/userprofiles`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({
  //           topicDetails: [],
  //           userFirstName: user,
  //           userLastName: lastName,
  //           userLastWatched: [],
  //           userNotes: [],
  //           userCart: []
  //         }),
  //       })
  //       res && setReferesh(true)
  //     }
  //   } catch (err) {}
  // }

  const displayRazorpay = async () => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?")
      return
    }

    // creating a new order

    const result = await fetch(
      `${process.env.STRAPI_API_URL}/orders/paymentv1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          cart: cart,
        }),
      }
    )

    if (!result) {
      alert("Server error. Are you online?")
      return
    }
    const data = await result.json()
    //:  Getting the order details back
    const { amount, id, currency } = data

    const options = {
      key: "rzp_test_KW2N2FS6nBI6ZK", // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: currency,
      name: "Analogica Software Development Pvt Ltd",
      description: "Test Transaction",
      image: "https://certisured.com/favicon.png",
      order_id: id,
      handler: async function (response) {
        const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
          response

        const result = await fetch(
          `${process.env.STRAPI_API_URL}/orders/success`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              orderCreationId: id,
              razorpayPaymentId: razorpay_payment_id,
              razorpayOrderId: razorpay_order_id,
              razorpaySignature: razorpay_signature,
              cart: cart,
              total: total,
            }),
          }
        )
        if (result.status === 200 && result.ok === true) {
          setRazorpaySuccess(true)
          props.handleCart(true)
          dispatch(clearCart())
          window.scrollTo({ behavior: "smooth", top: "0px" })
        }
      },

      notes: {
        address: "Certisured Rajajinagar",
      },
      theme: {
        color: "#61dafb",
      },
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  useEffect(() => {
    const loadToken = async () => {
      const response = await fetch(
        `${process.env.STRAPI_API_URL}/orders/payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            cart: cart,
            taxRate,
          }),
        }
      )

      const data = await response.json()
      setStripeToken(data.client_secret)
      setTotal(data.amount / 100)
    }
    loadToken()
  }, [cart])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement("card").focus()
      return
    }

    if (cardComplete) {
      setProcessing(true)
    }

    const result = await stripe.confirmCardPayment(stripeToken, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })
    console.log(result.paymentIntent)
    const data = {
      paymentIntent: result.paymentIntent,
      shipping_name: billingDetails.name,
      shipping_email: billingDetails.email,
      shipping_phone: billingDetails.phone,
      cart,
      stripeTotal,
    }

    fetch(`${process.env.STRAPI_API_URL}/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })

    setProcessing(false)

    if (result.error) {
      setError(result.error)
    } else {
      dispatch(clearCart())

      setPaymentMethod(result.paymentIntent)
      setSuccess(true)
      props.handleCart(result.paymentIntent)
      window.scrollTo({ behavior: "smooth", top: "0px" })
    }
  }

  const reset = () => {
    setError(null)
    setProcessing(false)
    setPaymentMethod(null)
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    })
  }
  const handlePayment = (value) => {
    setShowPayment(value)
  }

  return paymentMethod || razorpaySuccess ? (
    <div className="Result">
      <div className="image">
        <img
          className="success_gif"
          src={successGif}
          alt="payment successfull"
        />
      </div>
      <div className="ResultTitle" role="alert">
        <h1 className="payment_sucess">Payment Successfull</h1>
      </div>
      <div className="ResultMessage">
        <h2 className="result_message">
          You're order has been placed, go to profile & check for orders
        </h2>
      </div>
    </div>
  ) : (
    <Wrapper>
      {cart.length ? (
        <div className="form_wrapper">
          <h2 className="payment_info">payment info</h2>
          <RadioButton handlePayment={handlePayment} />
          {showPayment === "debit or credit card" && (
            <form className="Form" onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="name"
                name="name"
                autoComplete="name"
                autoFocus
                value={billingDetails.name}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
                onChange={(e) =>
                  setBillingDetails({
                    ...billingDetails,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={billingDetails.email}
                InputLabelProps={{
                  style: {
                    color: "#151515",
                    opacity: "0.8",
                    fontFamily: "var(--family)",
                    fontWeight: "500",
                  },
                }}
                onChange={(e) =>
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value,
                  })
                }
              />
              <fieldset className="FormGroup" style={{ padding: "20px 10px" }}>
                <CardElement
                  style={{ width: "100%" }}
                  options={CARD_OPTIONS}
                  onChange={(e) => {
                    setError(e.error)
                    setCardComplete(e.complete)
                  }}
                />
              </fieldset>

              {error && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ErrorMessage>{error.message}</ErrorMessage>{" "}
                  <ResetButton onClick={reset} />
                </div>
              )}
              {total ? (
                <SubmitButton
                  processing={processing}
                  error={error}
                  disabled={!stripe}
                >
                  Pay
                  {total
                    ? total.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })
                    : `loading..`}
                </SubmitButton>
              ) : null}
            </form>
          )}

          {total && showPayment === "upi payment" && (
            <div className="upi_button_wrapper">
              <button className="razorpay_link" onClick={displayRazorpay}>
                Pay{" "}
                {total
                  ? total.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })
                  : `loading..`}
              </button>
            </div>
          )}
        </div>
      ) : null}
    </Wrapper>
  )
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51JIRHuSExpOaRu25lwo8zdTBP9nDZuClaNBMD8vporsj4ONxLbEYd3Ns5BwansWt7q2HPWhkMA77RCHTq3ZgzFvB00l70stSjR"
)

const App = (props) => {
  const token = store.getState().user.token
  console.log("token" , token);
  const { cart, handleCart } = props
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        {token ? (
          <CheckoutForm cart={cart} handleCart={handleCart} />
        ) : (
          <div className="create_account_section">
            <div className="image1">
              {/* <StaticImage
                src="../../images/sign.png"
                alt="certisured signin"
                placeholder="tracedSVG"
              /> */}
            </div>
            <div>
              <div>
                <h1 className="app_h1">create an account or Login</h1>
              </div>
              <div className="link" style={{ marginTop: "2rem" }}>
                <Link className="create_account_link" to="/create-account">
                  create account
                </Link>
              </div>
            </div>
          </div>
        )}
      </Elements>
    </div>
  )
}

export default App

const Wrapper = styled.div`
  width: 100%;
  .form_wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    @media (max-width: 479px) {
      padding: 10px 20px;
    }
    .payment_info {
      font-weight: 600;
      font-size: 20px;
      color: #002f54;
    }
  }
  input {
    color: #000 !important;
  }
  .InputContainer .InputElement {
    color: #000 !important;
  }
  .reset_icon {
    font-size: 22px;
    color: var(--thirdColor);
  }
  .upi_button_wrapper {
    display: flex;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    .razorpay_link {
      min-width: 150px;
      padding: 15px 25px;
      background: var(--thirdColor);
      color: var(--secondaryColor);
      font-size: 1rem;
      font-weight: 500;
      border-radius: 50px;
      cursor: pointer;
    }
  }
`
